import { isBefore, isValid, parseISO } from 'date-fns'
import { z } from 'zod'

export const DateStringSchema = z
  .string()
  .refine(
    str => {
      const parsedDate = parseISO(str)
      return isValid(parsedDate)
    },
    {
      message: 'Invalid date format',
    },
  )
  .transform(str => new Date(str))

export const DateSchema = z.union([DateStringSchema, z.date()])

export const DateRangeFormSchema = z
  .object({
    from: DateSchema,
    to: DateSchema.optional(),
  })
  .refine(
    ({ from, to }) => {
      if (to) {
        return isBefore(from, to)
      }

      return true
    },
    {
      message: 'Start date must be before end date',
    },
  )
